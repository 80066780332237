form.position-relative .overlay {
	z-index: 1;
	background: rgba(121,121,121,0.1);
	border-radius: 3px;
}

form.position-relative .overlay {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
}

form.position-relative .overlay > i {
	position: absolute;
	top: 50%;
	left: 50%;
	margin-left: -15px;
	margin-top: -40px;
	color: #000;
}